import React from 'react';
import { Button } from '@mui/material';
import { Phone } from '@mui/icons-material';
import QuotaPopup from './QuotaPopup';
import mainHeroImage from '../assets/mainHeroImage.webp';

export default function Hero({ className }) {
  const [openPopup, setOpenPopup] = React.useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <div className={`relative flex items-center justify-center w-full h-[30rem] md:h-[46rem] font-poppins ${className}`}>
      <div
        className='absolute top-0 left-0 w-full h-full'
        style={{
          backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${mainHeroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right'
        }}
      ></div>
      <div className='relative z-10 flex flex-col items-center justify-center w-full max-w-4xl p-12 md:p-12'>
        <h1 className='text-3xl md:text-6xl font-bold mb-4 md:mb-8 text-center text-white md:text-nowrap'>
          Stucco done professionally
        </h1>
        <p className='text-base md:text-xl text-white mb-8 text-center font-light md:text-nowrap'>
        Expert stucco and construction services in Calgary, Alberta
        </p>
        <div className='flex flex-col md:flex-row md:space-x-4 items-center'>
          <Button 
            variant="contained" 
            onClick={handleOpenPopup}
            sx={{ 
              backgroundColor: '#ffffff', 
              color: '#333333', 
              fontWeight: 'bold', 
              borderRadius: '5px', 
              padding: '10px 20px', 
              width: { xs: '100%', md: 'auto' }, 
              marginBottom: { xs: '1rem', md: '0' }, 
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#333333', // Flip colors on hover
                color: '#ffffff',
              }
            }}
          >
            <span className='px-2'>$</span> Get a Quote
          </Button>
          <a href="tel:+14034026676" style={{ textDecoration: 'none' }}>
            <Button 
              variant="outlined" 
              startIcon={<Phone />}
              sx={{ 
                color: '#ffffff', 
                borderColor: '#ffffff', 
                fontWeight: 'bold', 
                borderRadius: '5px', 
                padding: '10px 20px', 
                width: { xs: '100%', md: 'auto' }, 
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#333333', // Flip colors on hover
                  color: '#333333',
                  backgroundColor: '#ffffff', // Needed for outlined button hover
                }
              }}
            >
              +1 (403) 402-6676
            </Button>
          </a>
        </div>
      </div>
      <QuotaPopup open={openPopup} handleClose={handleClosePopup} />
    </div>
  );
}
