import React from 'react';
import { Link } from 'react-router-dom';
import safewayImage from '../assets/projects/safeway/prev.jpg';
import coopImage from '../assets/projects/coop/prev.jpg';
import chimneyImage from '../assets/projects/chimney/prev.jpg';
import bownessImage from '../assets/projects/bowness/prev.jpg';

const projectData = [
  {
    id: 'safeway',
    title: 'Safeway Garrison Woods',
    description: 'In this project, we addressed the unsightly cracks and damage that have developed on the exterior of the property.',
    previewImage: safewayImage,
  },
  {
    id: 'sobeys',
    title: 'Sobeys - Forest Lawn',
    description: 'We pride ourselves on our stucco repair expertise, and our project at Sobeys Forest Lawn exemplifies our commitment to quality.',
    previewImage: coopImage,
  },
  {
    id: 'chimney',
    title: 'Chimney Repair and Painting Transformation',
    description: 'We expertly repaired and painted chimneys, using textured stucco, smooth stucco, and foam trims around the windows to enhance the appearance and durability of the property.',
    previewImage: chimneyImage,
  },
  {
    id: 'bowness',
    title: 'Duplex in the Community of Bowness',
    description: 'We provided exceptional stucco installation services for a newly built duplex in the vibrant community of Bowness.',
    previewImage: bownessImage,
  },
  // Add more project items as needed
];

export default function ProjectsSlider() {
  return (
    <div>
      <Link to="/projects">
        <h2 className='text-3xl md:text-4xl font-extrabold text-left mb-2 text-gray-900 hover:underline hover:cursor-pointer'>
          Projects
        </h2>
      </Link>
      <p className='text-base md:text-lg text-left text-gray-700 mb-6'>
        Explore our recent projects and exceptional craftsmanship.
      </p>
      <div className='flex overflow-x-auto space-x-2 mt-4 md:mt-10 p-2'>
        {projectData.map((project, index) => (
          <Link key={index} to={`/projects#${project.id}`} className='flex-none w-[250px] md:w-[300px] p-4 rounded-lg'>
            <div className='h-[120px] md:h-[200px] bg-cover bg-center rounded-lg' style={{ backgroundImage: `url(${project.previewImage})` }}></div>
            <h3 className='mt-4 font-bold text-[14px] md:text-[16px]'>{project.title}</h3>
            <p className='text-gray-600 text-[12px] md:text-[14px]'>{project.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}
