import React from 'react'
import Header from '../components/Header'

export default function Services() {
  return (
    <div>
        <Header />
        <h1>Services</h1>
    </div>
  )
}
