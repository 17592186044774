import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import HomePage from './pages/HomePage';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Projects from './pages/Projects';
import ScrollToTop from './components/ScrollToTop';
import Blog from './pages/Blog';

// Import the logo image
import logo from './assets/logo.jpeg';

function App() {
  return (
    <Router>
      <Helmet>
        <title>ALAMINA Stucco</title>
        <meta name="description" content="Web site created using create-react-app" />
        <link rel="icon" href={logo} />
        <link rel="apple-touch-icon" href={logo} />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
    </Router>
  );
}

export default App;
