import React from 'react';
import coop_logo from '../assets/coop-logo.jpg';
import sobeys_logo from '../assets/sobeys-logo.png';
import safeway from '../assets/safeway.png';

const PartnersSection = () => {
  return (
    <div>
      <h2 className='text-3xl md:text-4xl font-extrabold text-left mb-2 text-gray-900'>
        Customers
      </h2>
      <p className='text-base md:text-lg text-left text-gray-700 mb-4'>
        We proudly collaborate with esteemed customers to deliver outstanding results.
      </p>

      <div className="w-full flex justify-center py-4">
        {/* Mobile */}
        <div className="flex md:hidden justify-center items-center space-x-4">
          <a href="https://www.co-op.crs/" target="_blank" rel="noopener noreferrer">
            <img src={coop_logo} alt="Coop" className="h-20" />
          </a>
          <a href="https://www.sobeys.com/" target="_blank" rel="noopener noreferrer">
            <img src={sobeys_logo} alt="Sobeys" className="h-20" />
          </a>
          <a href="https://www.safeway.com/" target="_blank" rel="noopener noreferrer">
            <img src={safeway} alt="Safeway" className="h-5" />
          </a>
        </div>

        {/* Desktop + */}
        <div className="hidden md:flex space-x-8">
          <a href="https://www.co-op.crs/" target="_blank" rel="noopener noreferrer">
            <img src={coop_logo} alt="Coop" className="h-40" />
          </a>
          <a href="https://www.sobeys.com/" target="_blank" rel="noopener noreferrer">
            <img src={sobeys_logo} alt="Sobeys" className="h-40" />
          </a>
          <a href="https://www.safeway.com/" target="_blank" rel="noopener noreferrer">
            <img src={safeway} alt="Safeway" className="h-10 mt-14" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PartnersSection;
