import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GeneralHero from '../components/GeneralHero';
import aboutHeroImage from '../assets/aboutus.webp';

const AboutUs = () => {
  return (
    <div className='w-full h-full bg-gray-100 font-poppins'>
      <Header />
      <GeneralHero 
        title="About Us"
        description="Learn more about our journey and services."
        backgroundImage={aboutHeroImage}
      />
      <div className='bg-white max-w-7xl mx-auto p-6 md:p-12 rounded-lg shadow-lg'>
        <section className='mb-16'>
          <div className='mb-12'>
            <h3 className='text-2xl font-bold mb-4'>Our Story</h3>
            <p className='text-gray-700 leading-relaxed mb-4'>
              Since our establishment in 2012, we have built a reputation as Alberta’s most reliable stucco provider. We offer a wide range of services to meet the diverse needs of our clients. Whatever your vision, our team of experts is dedicated to turning your dreams into reality. Contact us today for a free estimate and take the first step toward transforming your project.
            </p>
            <p className='text-gray-700 leading-relaxed mb-4'>
              We work nights and weekends and provide two million dollar liability insurance through the Worker’s Compensation Board (WCB). Our owner takes a hands-on approach, personally overseeing all aspects of the projects, including estimation, consultation, execution, and inspection. Our comprehensive photo gallery showcases the numerous successful projects we’ve completed, highlighting the exceptional quality we consistently deliver.
            </p>
          </div>

          <div className='mb-12'>
            <h3 className='text-2xl font-bold mb-4'>Our Services</h3>
            <ul className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-gray-700 leading-relaxed mb-4'>
              <li>Textured Acrylic Finishes</li>
              <li>Smooth Acrylic Finishes</li>
              <li>EIFS Stucco</li>
              <li>Stucco Repair</li>
              <li>Stucco Painting</li>
              <li>Exterior Painting</li>
              <li>Masonry / Stone</li>
              <li>Parging and Bases</li>
              <li>Paper and Wire</li>
              <li>Stucco Tear Down</li>
              <li>Praging</li>
            </ul>
            <p className='text-gray-700 leading-relaxed'>
              By choosing our company for stucco repair services in Calgary, you can say goodbye to unattractive cracks, chipped or faded paint, and damage to your stucco system. With our stucco painting and repair services, you can transform your home into something fresh and captivating. Contact us today and discover the many benefits Magnum Stucco offers to all our valued clients.
            </p>
          </div>
        </section>

        <section className='mb-16'>
          <h2 className='text-3xl font-bold mb-6 text-left'>What We Offer</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            <div>
              <h3 className='text-2xl font-bold mb-4'>Residential Stucco</h3>
              <p className='text-gray-700 leading-relaxed'>
                Beautify the exterior of your home.
              </p>
            </div>
            <div>
              <h3 className='text-2xl font-bold mb-4'>Commercial Stucco</h3>
              <p className='text-gray-700 leading-relaxed'>
                Attention to detail for your business.
              </p>
            </div>
            <div>
              <h3 className='text-2xl font-bold mb-4'>Repair/Restoration Services</h3>
              <p className='text-gray-700 leading-relaxed'>
                Customized to your preferences.
              </p>
            </div>
            <div>
              <h3 className='text-2xl font-bold mb-4'>Exterior and Stucco Painting</h3>
              <p className='text-gray-700 leading-relaxed'>
                Efficient. Reliable. Exceptional.
              </p>
            </div>
          </div>
        </section>

        <section className='mb-16'>
          <h2 className='text-2xl font-bold mb-6 text-left'>Professional & Institutional</h2>
          <p className='text-gray-700 leading-relaxed'>
            Our experienced team has a proven track record in new builds and tenant improvements.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
