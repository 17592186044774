import React from 'react';

export default function GeneralHero({ title, description, backgroundImage, className }) {
  return (
    <div className={`relative flex items-center justify-center w-full h-[30rem] md:h-[46rem] font-poppins ${className}`}>
      <div
        className='absolute top-0 left-0 w-full h-full'
        style={{
          backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right'
        }}
      ></div>
      <div className='relative z-10 flex flex-col items-center justify-center w-full max-w-4xl p-12 md:p-12'>
        <h1 className='text-3xl md:text-6xl font-bold mb-4 md:mb-8 text-center text-white md:text-nowrap'>
          {title}
        </h1>
        <p className='text-base md:text-xl text-white mb-8 text-center font-light md:text-nowrap'>
          {description}
        </p>
      </div>
    </div>
  );
}
