import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import backgroundImage from '../assets/contactus.webp';
import emailjs from 'emailjs-com';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    note: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_ecs1xno', 'template_c177wzt', {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      note: formData.note,
    }, 'RhG_ohKw7DarWr4-7')
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setSnackbar({ open: true, message: 'Email sent successfully!', severity: 'success' });
        setFormData({ name: '', email: '', phone: '', note: '' }); // Clear the form
      }, (error) => {
        console.log('Failed to send email:', error);
        setSnackbar({ open: true, message: 'Failed to send email. Please try again.', severity: 'error' });
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className='flex flex-col min-h-screen font-poppins'>
      <Header />
      <div
        className='relative flex-grow bg-cover flex items-center justify-center'
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className='absolute inset-0 bg-black opacity-50'></div>
        <div className="relative z-10 flex flex-col md:flex-row items-start justify-center w-full md:space-x-20 mx-8 md:mx-12 mt-10 md:mt-14 md:px-[90px]">
          <div className="text-left mb-6 md:mb-0 text-white md:mt-20">
            <h2 className="text-3xl md:text-5xl font-bold mb-4">Contact Us</h2>
            <p className="flex items-center mb-2">
              <span className="mr-2">📞</span>+403-402-6676
            </p>
            <p className="flex items-center mb-2">
              <span className="mr-2">📞</span>+403-973-5176
            </p>
            <p className="flex items-center mb-2">
              <span className="mr-2">✉️</span>alaminaltd@gmail.com
            </p>
            <p className="flex items-center mb-2">
              <span className="mr-2">📍</span>7400 Macleod Trail SW, Calgary AB
            </p>
          </div>

          <form onSubmit={handleSubmit} className="bg-white p-6 md:p-12 rounded-md shadow-md w-full md:w-1/2 mb-8 space-y-4">
            <TextField
              label="Name"
              fullWidth
              variant="standard"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              label="E-mail"
              variant="standard"
              fullWidth
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              label="Phone"
              variant="standard"
              fullWidth
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <TextField
              label="Please describe the project you need help with."
              variant="standard"
              fullWidth
              multiline
              id="note"
              name="note"
              value={formData.note}
              onChange={handleChange}
              rows={4}
            />
            <div className='flex justify-center'>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="bg-black text-white py-2"
                style={{
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '4px',
                  width: '100%' // Make button full width on mobile
                }}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
