import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import GeneralHero from '../components/GeneralHero';
import { safewayImages, coopImages, bownessImages, chimneyImages } from '../utils/importAllImages';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import heroBackground from '../assets/projects.webp';

const projects = [
  {
    id: 'safeway',
    title: 'Safeway Garrison Woods',
    content: [
      { type: 'text', value: `In this project, we addressed the unsightly cracks and damage that have developed on the exterior of the property. Our experienced team thoroughly assessed the affected areas, identifying the underlying causes and determining the most effective repair techniques.` },
      { type: 'image', value: safewayImages[2] },
      { type: 'image', value: safewayImages[1] },
      { type: 'text', value: `Using high-quality materials and precision craftsmanship, we skillfully patched and reinforced the damaged stucco, ensuring a seamless integration with the existing finish. Our attention to detail extends to color matching, ensuring that the repaired areas blend seamlessly with the surrounding surface.` },
      { type: 'image', value: safewayImages[0] },
      { type: 'image', value: safewayImages[3] },
      { type: 'text', value: `The result is a revitalized and structurally sound stucco finish that enhances the overall appearance and durability of your property.` },
      { type: 'image', value: safewayImages[4] },
    ]
  },
  {
    id: 'sobeys',
    title: 'Sobeys - Forest Lawn',
    content: [
      { type: 'text', value: `We pride ourselves on our stucco repair expertise, and our project at Sobeys Forest Lawn exemplifies our commitment to quality.` },
      { type: 'image', value: coopImages[0] },
      { type: 'text', value: `Our skilled professionals meticulously assessed and addressed the stucco issues, repairing cracks and damages to achieve a seamless, flawless finish.` },
      { type: 'image', value: coopImages[1] },
      { type: 'text', value: `This not only enhanced the aesthetics of Sobeys but also improved the durability and longevity of their exterior.` },
      { type: 'image', value: coopImages[2] },
      { type: 'text', value: `We are proud to have contributed to the enhanced appearance and structural integrity of Sobeys through our stucco repair services.` },
      { type: 'image', value: coopImages[3] }
    ]
  },
  {
    id: 'chimney',
    title: 'Chimney Repair and Painting Transformation',
    content: [
      { type: 'text', value: 'We expertly repaired and painted chimneys, using textured stucco, smooth stucco, and foam trims around the windows to enhance the appearance and durability of the property.' },
      { type: 'image', value: chimneyImages[0] },
      { type: 'image', value: chimneyImages[1] },
      { type: 'image', value: chimneyImages[2] },
      { type: 'image', value: chimneyImages[3] },
      { type: 'image', value: chimneyImages[4] }
    ]
  },
  {
    id: 'bowness',
    title: 'Duplex in the Community of Bowness',
    content: [
      { type: 'text', value: 'We provided exceptional stucco installation services for a newly built duplex in the vibrant community of Bowness. From the initial consultation to the final touches, we worked closely with homeowners to understand their vision and deliver stunning results.' },
      { type: 'image', value: bownessImages[0] },
      { type: 'image', value: bownessImages[1] },
      { type: 'image', value: bownessImages[2] },
      { type: 'image', value: bownessImages[3] },
      { type: 'image', value: bownessImages[4] }
    ]
  }
];

export default function ProjectsPage() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className='w-full h-full bg-gray-100 overflow-hidden font-poppins'>
      <Header />
      <GeneralHero 
        title="Our Projects"
        description="Explore our diverse range of completed projects showcasing our expertise and commitment to quality."
        backgroundImage={heroBackground}
      />
      <div className='bg-white max-w-7xl mx-auto p-6 md:p-12 rounded-lg shadow-lg'>
        {projects.map((project, index) => (
          <div key={index} id={project.id} className='mb-16'>
            <h2 className='text-3xl font-bold mb-6 text-center md:text-left'>{project.title}</h2>
            {/* Mobile View */}
            <div className='block md:hidden'>
              {project.content.map((item, itemIndex) => (
                item.type === 'text' ? (
                  <p key={itemIndex} className='text-gray-700 leading-relaxed mb-6'>{item.value}</p>
                ) : (
                  <img
                    key={itemIndex}
                    src={item.value}
                    alt={`Project ${index + 1} Image ${itemIndex + 1}`}
                    className='w-full h-64 object-cover rounded-lg shadow-lg cursor-pointer mb-6'
                    onClick={() => handleOpen(item.value)}
                  />
                )
              ))}
            </div>
            {/* Desktop View */}
            <div className='hidden md:block'>
              {project.content.filter(item => item.type === 'text').map((item, itemIndex) => (
                <p key={itemIndex} className='text-gray-700 leading-relaxed mb-6'>{item.value}</p>
              ))}
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                {project.content.filter(item => item.type === 'image').map((item, itemIndex) => (
                  <img
                    key={itemIndex}
                    src={item.value}
                    alt={`Project ${index + 1} Image ${itemIndex + 1}`}
                    className='w-full h-64 object-cover rounded-lg shadow-lg cursor-pointer mb-6'
                    onClick={() => handleOpen(item.value)}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='image-modal-title'
          aria-describedby='image-modal-description'
        >
          <Box
            className='flex items-center justify-center'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: '900px',
              bgcolor: 'background.paper',
              boxShadow: 24,
              outline: 'none',
              '@media (min-width: 768px)': {
                width: 'auto',
                height: '90%',
              },
            }}
          >
            {selectedImage && <img src={selectedImage} alt='Selected Project' className='max-w-full max-h-full object-contain' />}
          </Box>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
