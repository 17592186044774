// Help with importing all images from a directory

export const importAll = (r) => r.keys().map(r);

// This will import all images from the 'projects/safeway' directory
export const safewayImages = importAll(require.context('../assets/projects/safeway', false, /\.(png|jpe?g|svg)$/));
export const coopImages = importAll(require.context('../assets/projects/coop', false, /\.(png|jpe?g|svg)$/));
export const chimneyImages = importAll(require.context('../assets/projects/chimney', false, /\.(png|jpe?g|svg)$/));
export const bownessImages = importAll(require.context('../assets/projects/bowness', false, /\.(png|jpe?g|svg)$/));

