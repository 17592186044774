import React from 'react';
import { Link } from 'react-router-dom';
import { Construction, Build, Brush, Home, Business } from '@mui/icons-material';

const services = [
  {
    id: 'textured-acrylic-finishes',
    icon: <Build fontSize="large" />,
    title: 'Textured Acrylic Finishes',
    description: 'High-quality textured finishes that enhance the appearance of your property.'
  },
  {
    id: 'smooth-acrylic-finishes',
    icon: <Brush fontSize="large" />,
    title: 'Smooth Acrylic Finishes',
    description: 'Smooth finishes for a sleek and modern look.'
  },
  {
    id: 'eifs-stucco',
    icon: <Construction fontSize="large" />,
    title: 'EIFS Stucco',
    description: 'Durable and energy-efficient Exterior Insulation and Finish Systems.'
  },
  {
    id: 'stucco-repair',
    icon: <Construction fontSize="large" />,
    title: 'Stucco Repair',
    description: 'Expert repair services to fix cracks, chips, and other stucco damage.'
  },
  {
    id: 'stucco-painting',
    icon: <Brush fontSize="large" />,
    title: 'Stucco Painting',
    description: 'Professional stucco painting services to refresh the look of your property.'
  },
  {
    id: 'exterior-painting',
    icon: <Home fontSize="large" />,
    title: 'Exterior Painting',
    description: 'High-quality exterior painting services to protect and beautify your home.'
  },
  {
    id: 'masonry-stone',
    icon: <Business fontSize="large" />,
    title: 'Masonry / Stone',
    description: 'Skilled masonry work for both residential and commercial properties.'
  },
  {
    id: 'parging-and-bases',
    icon: <Build fontSize="large" />,
    title: 'Parging and Bases',
    description: 'Expert parging services for a clean and finished look.'
  },
  {
    id: 'paper-and-wire',
    icon: <Construction fontSize="large" />,
    title: 'Paper and Wire',
    description: 'Professional installation of paper and wire for stucco applications.'
  },
  {
    id: 'stucco-tear-down',
    icon: <Construction fontSize="large" />,
    title: 'Stucco Tear Down',
    description: 'Safe and efficient removal of old stucco.'
  },
];

export default function Services() {
  return (
    <div className='py-2 md:py-3 bg-white'>
      <div className='container mx-auto'>
        <h2 className='text-3xl md:text-4xl font-extrabold text-left mb-2 text-gray-900 hover:underline hover:cursor-pointer'>Services</h2>
        <p className='text-base md:text-lg text-left text-gray-700 mb-6'>
          Explore our wide range of services tailored to meet your needs.
        </p>
        <div className='flex overflow-x-auto space-x-6 p-4'>
          {services.map((service) => (
            <Link key={service.id} to={`/blog#${service.id}`} className='flex-none w-[250px] md:w-[300px] p-6 bg-white border border-gray-200 rounded-lg shadow-md text-center'>
              <div className='mb-4 text-gray-900'>
                {service.icon}
              </div>
              <h3 className='text-lg font-semibold mb-2 text-gray-900'>{service.title}</h3>
              <p className='text-gray-600'>{service.description}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
