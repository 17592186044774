import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import PartnersSection from '../components/PartnersSection';
import Divider from '../components/Divider';
import Footer from '../components/Footer';
import ProjectsSlider from '../components/ProjectsSlider';
import Services from '../components/Services';

export default function HomePage() {
  return (
    <div className='w-full h-full bg-white overflow-hidden'>
      <Header />
      
      <Hero className="no-padding" />

      <div className='md:px-12 md:pt-4 md:pl-[90px] md:pr-[90px] mx-4 mt-10 pl-4 pr-4'>
        {/* <Divider /> */}
        <Services />
        <Divider />
        <ProjectsSlider />
        <Divider />
        <PartnersSection />
      </div>

      <Footer />
    </div>
  );
}
