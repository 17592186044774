import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import backgroundImage from '../assets/blog.webp';
import EIFSStucco from '../assets/services/EIFSStucco.webp';
import ExteriorPainting from '../assets/services/ExteriorPainting.webp';
import MasonryStone from '../assets/services/Masonry_Stone.webp';
import PargingBases from '../assets/services/PargingandBases.webp';
import PaperWire from '../assets/services/PaperandWire.webp';
import StuccoPainting from '../assets/services/StuccoPainting.webp';
import StuccoRepair from '../assets/services/StuccoRepair.webp';
import StuccoTearDown from '../assets/services/StuccoTearDown.webp';
import TexturedAcrylicFinishes from '../assets/services/TexturedAcrylicFinishes.webp';
import SmoothAcrylicFinishes from '../assets/services/SmoothAcrylicFinishes.webp';
import QuotaPopup from '../components/QuotaPopup';

const blogPosts = {
  'textured-acrylic-finishes': {
    title: 'Textured Acrylic Finishes',
    content: 'High-quality textured finishes that enhance the appearance of your property. AL AMINA Stucco provides expert application of textured acrylic finishes, ensuring a durable and aesthetically pleasing result for your property.',
    image: TexturedAcrylicFinishes
  },
  'smooth-acrylic-finishes': {
    title: 'Smooth Acrylic Finishes',
    content: 'Smooth finishes for a sleek and modern look. Our smooth acrylic finishes provide a sleek and contemporary appearance that enhances the visual appeal of any property.',
    image: SmoothAcrylicFinishes
  },
  'eifs-stucco': {
    title: 'EIFS Stucco',
    content: 'Durable and energy-efficient Exterior Insulation and Finish Systems. AL AMINA Stucco offers EIFS solutions that provide superior insulation and a high-quality finish.',
    image: EIFSStucco
  },
  'stucco-repair': {
    title: 'Stucco Repair',
    content: 'Expert repair services to fix cracks, chips, and other stucco damage. Our stucco repair services address all types of damage to restore the integrity and appearance of your stucco surfaces.',
    image: StuccoRepair
  },
  'stucco-painting': {
    title: 'Stucco Painting',
    content: 'Professional stucco painting services to refresh the look of your property. AL AMINA Stucco’s painting services breathe new life into your stucco surfaces with professional, high-quality paint application.',
    image: StuccoPainting
  },
  'exterior-painting': {
    title: 'Exterior Painting',
    content: 'High-quality exterior painting services to protect and beautify your home. We provide exterior painting services that not only enhance the appearance but also protect your property from the elements.',
    image: ExteriorPainting
  },
  'masonry-stone': {
    title: 'Masonry / Stone',
    content: 'Skilled masonry work for both residential and commercial properties. Our masonry services include the installation and repair of stone and brickwork, adding durability and aesthetic value to your property.',
    image: MasonryStone
  },
  'parging-and-bases': {
    title: 'Parging and Bases',
    content: 'Expert parging services for a clean and finished look. We offer parging services to give your foundation walls a smooth and finished appearance, protecting them from weather and wear.',
    image: PargingBases
  },
  'paper-and-wire': {
    title: 'Paper and Wire',
    content: 'Professional installation of paper and wire for stucco applications. Our team installs paper and wire with precision to ensure a solid foundation for your stucco applications.',
    image: PaperWire
  },
  'stucco-tear-down': {
    title: 'Stucco Tear Down',
    content: 'Safe and efficient removal of old stucco. We provide professional stucco removal services to prepare your property for new installations or other renovations.',
    image: StuccoTearDown
  },
};

export default function Blog() {
  const location = useLocation();
  const serviceId = location.hash.replace('#', '');
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    if (serviceId) {
      const element = document.getElementById(serviceId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        element.classList.add('highlight');
        setTimeout(() => {
          element.classList.remove('highlight');
        }, 2000);
      }
    }
  }, [serviceId]);

  return (
    <div className='flex flex-col min-h-screen'>
      <Header />
      <div
        className='relative bg-cover bg-center h-64 md:h-96 flex items-center justify-center'
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className='absolute inset-0 bg-black opacity-50'></div>
        <div className='relative z-10 text-center text-white p-4'>
          <h1 className='text-3xl md:text-5xl font-bold mb-4'>Our Blog</h1>
          <p className='text-base md:text-lg'>Learn more about our services and what AL AMINA Stucco can do for you.</p>
          <Button 
            variant="contained" 
            onClick={handleOpenPopup}
            sx={{ 
              backgroundColor: '#ffffff', 
              color: '#333333', 
              fontWeight: 'bold', 
              borderRadius: '5px', 
              padding: '10px 20px', 
              margin: { xs: '1rem 0', md: '1rem'},
              width: { xs: 'auto', md: 'auto' }, 
              marginBottom: { xs: '1rem', md: '0' }, 
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#333333', // Flip colors on hover
                color: '#ffffff',
              }
            }}
          >
            Get a Quote
          </Button>
        </div>
      </div>
      <main className='flex-grow'>
        <div className='container mx-auto p-6 md:p-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
          {Object.entries(blogPosts).map(([key, post]) => (
            <div key={key} id={key} className='bg-white shadow-lg rounded-lg overflow-hidden'>
              <img src={post.image} alt={post.title} className='w-full h-48 object-cover'/>
              <div className='p-6'>
                <h2 className='text-2xl font-bold mb-4'>{post.title}</h2>
                <p className='text-gray-700'>{post.content}</p>
              </div>
            </div>
          ))}
        </div>
      </main>
      <Footer />
      <QuotaPopup open={openPopup} handleClose={handleClosePopup} />
    </div>
  );
}
