import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import emailjs from 'emailjs-com';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  p: 4,
  overflowY: 'auto',
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
};

const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.09)',
};

export default function QuotaPopup({ open, handleClose }) {
  const [service, setService] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    projectDescription: '',
  });
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; // Simple regex for a 10-digit phone number

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Invalid phone number format';
    }
    if (!service) newErrors.service = 'Service is required';
    if (!formData.projectDescription) newErrors.projectDescription = 'Project description is required';
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      emailjs.send('service_ecs1xno', 'template_7tlx5dh', {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        service: service,
        projectDescription: formData.projectDescription,
      }, 'RhG_ohKw7DarWr4-7')
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setSnackbar({ open: true, message: 'Email sent successfully!', severity: 'success' });
      }, (error) => {
        console.log('Failed to send email:', error);
        setSnackbar({ open: true, message: 'Failed to send email. Please try again.', severity: 'error' });
      });

      handleClose();
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="quote-popup-title"
        aria-describedby="quote-popup-description"
        sx={overlayStyle}
      >
        <Box sx={{ ...style, maxHeight: { xs: '80vh', md: '100vh' } }}>
          <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" component="h3" className="text-center font-bold mt-2 text-gray-900">
            Get a Price Estimate
          </Typography>

          <Box className="my-4" style={{ overflowY: 'auto', flexGrow: 1 }}>
            <Typography className='font-bold text-gray-700 text-sm mb-4 pb-6'>
              Provide your contact information and a brief description of your project and we will get back to you with a price estimate.
            </Typography>

            <form className="space-y-3" onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={formData.name}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                label="E-mail"
                name="email"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={formData.email}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                label="Phone"
                name="phone"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={formData.phone}
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <TextField
                label="Address"
                name="address"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={formData.address}
              />
              <FormControl fullWidth variant="standard" error={!!errors.service}>
                <InputLabel shrink>Select Service</InputLabel>
                <Select
                  value={service}
                  onChange={handleServiceChange}
                  label="Select Service"
                >
                  <MenuItem value="Stucco Contractors">Stucco Contractors</MenuItem>
                  <MenuItem value="Stucco Repair">Stucco Repair</MenuItem>
                  <MenuItem value="Stucco Companies">Stucco Companies</MenuItem>
                  <MenuItem value="Stucco Plaster">Stucco Plaster</MenuItem>
                  <MenuItem value="Plaster Contractor">Plaster Contractor</MenuItem>
                  <MenuItem value="Stucco Installation">Stucco Installation</MenuItem>
                  <MenuItem value="Exterior Painting">Exterior Painting</MenuItem>
                  <MenuItem value="Praging">Praging</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {errors.service && <Typography color="error" variant="caption">{errors.service}</Typography>}
              </FormControl>

              <TextField
                label="Please describe the project you need help with."
                name="projectDescription"
                variant="standard"
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                value={formData.projectDescription}
                error={!!errors.projectDescription}
                helperText={errors.projectDescription}
              />

              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="bg-black text-white py-2"
                sx={{
                  backgroundColor: '#333333',
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </form>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
