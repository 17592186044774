import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Google } from '@mui/icons-material';

const Footer = () => {
  const location = useLocation();

  const handleReload = (path) => {
    if (location.pathname === path) {
      window.location.reload();
    }
  };

  return (
    <footer className="bg-black text-white pt-14 pb-6 px-4 md:px-[120px]">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 flex flex-wrap justify-center md:justify-start items-center space-x-4">
            <Link to="/home" className="hover:underline" onClick={() => handleReload('/home')}>
              <h3 className="text-lg md:text-xl font-bold mb-2">Home</h3>
            </Link>
            <div className="w-2 h-2 mb-2 bg-white rounded-full hidden md:block"></div>
            <Link to="/aboutus" className="hover:underline" onClick={() => handleReload('/aboutus')}>
              <h3 className="text-lg md:text-xl font-bold mb-2">About Us</h3>
            </Link>
            <div className="w-2 h-2 mb-2 bg-white rounded-full hidden md:block"></div>
            <Link to="/projects" className="hover:underline" onClick={() => handleReload('/projects')}>
              <h3 className="text-lg md:text-xl font-bold mb-2">Projects</h3>
            </Link>
            <div className="w-2 h-2 mb-2 bg-white rounded-full hidden md:block"></div>
            <Link to="/contactus" className="hover:underline" onClick={() => handleReload('/contactus')}>
              <h3 className="text-lg md:text-xl font-bold mb-2">Contact Us</h3>
            </Link>
          </div>

          <div className="w-full md:w-1/2 md:mb-0 text-center md:text-right">
            <p className="text-sm md:text-lg py-2"><span className='pr-3'>✉️</span>alaminaltd@gmail.com</p>
            <p className="text-sm md:text-lg py-2"><span className='pr-3'>📍</span>7400 Macleod Trail SW, Calgary AB</p>
            <p className="text-sm md:text-lg py-2">
              <span className='pr-3'>📞</span>
              <a href="tel:+14034026676" className="hover:underline">+1(403) 402-6676</a>
            </p>
            <p className="text-sm md:text-lg py-2">
              <span className='pr-3'>📞</span>
              <a href="tel:+14039735176" className="hover:underline">+1(403) 973-5176</a>
            </p>
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-center mt-8 border-t border-gray-700 pt-4">
          <div className="text-sm text-center md:text-left w-full md:w-auto mb-4 md:mb-0">
            <p>&copy; 2024 AL AMINA Co. All rights reserved</p>
          </div>
          <div className="flex justify-center md:justify-end w-full md:w-auto space-x-4">
            <a href="/" className="text-white">
              <Google fontSize="large" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default Footer;
